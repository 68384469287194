import Abinadi from "./images/Abinadi.jpg"
import Alma_the_Younger from "./images/Alma the Younger.jpg"
import Ammon from "./images/Ammon.jpg"
import Amulek from "./images/Amulek.jpg"
import Benjamin from "./images/Benjamin.jpg"
import Captain_Moroni from "./images/Captain Moroni.jpg"
import Giddianhi from "./images/Giddianhi.jpg"
import Helaman from "./images/Helaman.jpg"
import Jacob from "./images/Jacob.jpg"
import Korihor from "./images/Korihor.jpg"
import Lehi from "./images/Lehi.jpg"
import Mormon from "./images/Mormon.jpg"
import Moroni from "./images/Moroni.jpg"
import Mosiah from "./images/Mosiah.jpg"
import Nephi_son_of_Helaman from "./images/Nephi Son of Helaman.jpg"
import Nephi from "./images/Nephi.jpg"
import Pahoran from "./images/Pahoran.jpg"

import Sariah from "./images/Sariah.jpg"
import Amaleki from "./images/Amaleki.jpg"
import Lamoni from "./images/Lamoni.jpg"
import Laman from "./images/Laman.jpg"
import Alma from "./images/Alma.jpg"
import Jesus from "./images/Jesus.jpg"
import Brother_of_Jared from "./images/Brother of Jared.jpg"
import Isaiah from "./images/Isaiah.jpg"
import Helaman_son_of_Helaman from "./images/Helaman Son of Helaman.jpg"
import People_of_Benjamin from "./images/People of Benjamin.jpg"
import Zenos from "./images/Zenos.jpg"

import Enos from "./images/Enos.jpg"
import Jarom from "./images/Jarom.jpg"
import Zeniff from "./images/Zeniff.jpg"
import Limhi from "./images/Limhi.jpg"
import Gideon from "./images/Gideon.jpg"
import Ammon_the_Mulekite from "./images/Ammon the Mulekite.jpg"
import Chief_Judge from "./images/Chief Judge.jpg"
import Lamoni_Wife from "./images/Wife of Lamoni.jpg"
import Ammoron from "./images/Ammoron.jpg"
import Samuel from "./images/Samuel.jpg"


const ImageLoader = name => {
  if (name === null) {
    return "none";
  }

  const networkArray = {
    './images/Abinadi.jpg': Abinadi,
    './images/Alma the Younger.jpg': Alma_the_Younger,
    './images/Ammon.jpg': Ammon,
    './images/Amulek.jpg': Amulek,
    './images/Benjamin.jpg': Benjamin,
    './images/Captain Moroni.jpg': Captain_Moroni,
    './images/Giddianhi.jpg': Giddianhi,
    './images/Helaman.jpg': Helaman,
    './images/Jacob.jpg': Jacob,
    './images/Korihor.jpg': Korihor,
    './images/Lehi.jpg': Lehi,
    './images/Mormon.jpg': Mormon,
    './images/Moroni.jpg': Moroni,
    './images/Mosiah.jpg': Mosiah,
    './images/Nephi Son of Helaman.jpg': Nephi_son_of_Helaman,
    './images/Nephi.jpg': Nephi,
    './images/Pahoran.jpg': Pahoran,

    './images/Sariah.jpg': Sariah,
    "./images/Amaleki.jpg": Amaleki,
    "./images/Lamoni.jpg": Lamoni,
    "./images/Laman.jpg": Laman,
    "./images/Alma.jpg": Alma,
    "./images/Jesus.jpg": Jesus,
    "./images/Brother of Jared.jpg": Brother_of_Jared,
    "./images/Isaiah.jpg": Isaiah,
    "./images/Helaman Son of Helaman.jpg": Helaman_son_of_Helaman,
    "./images/People of Benjamin.jpg": People_of_Benjamin,
    "./images/Zenos.jpg": Zenos,

    "./images/Enos.jpg": Enos,
    "./images/Jarom.jpg": Jarom,
    "./images/Zeniff.jpg": Zeniff,
    "./images/Limhi.jpg": Limhi,
    "./images/Gideon.jpg": Gideon,
    "./images/Ammon the Mulekite.jpg": Ammon_the_Mulekite,
    "./images/Chief Judge.jpg": Chief_Judge,
    "./images/Wife of Lamoni.jpg": Lamoni_Wife,
    "./images/Ammoron.jpg": Ammoron,
    "./images/Samuel.jpg": Samuel
  };

  return networkArray[name];
}
 
export default ImageLoader;