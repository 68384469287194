import { Link } from "react-router-dom";

const Connections = () => {
  return ( 
    <div> 
      <iframe src="https://bookofmormonexplorer.org/Widgets/Connections/index.html" style={{height: "800px", width: "100%", border:"none", paddingTop:'1em'}} ></iframe>
      <br/><br/><br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
    </div>
   );
}

export default Connections;

// <h3 style={{textAlign:'center', padding: '1em', borderBottom: 'solid', borderRadius:'10%', borderColor:'lightblue'}}>Notice: this widget is still under development and may undergo changes.</h3>