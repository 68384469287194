import { Link } from "react-router-dom";
const BOMSemanticMap = () => {
    return ( 
      <div> 
        <h3 style={{textAlign:'center', padding: '1em'}}>Semantic Map of the Book of Mormon</h3>
        <iframe src="https://bookofmormonexplorer.org/Widgets/SemanticMaps/BOMSemanticMap/index.html" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
        <br/>
        <br/>
        {/* This component is present in all pages except Feedback */}
        <Link to="/Feedback" className="footer"/>
      </div>
     );
  }
   
  export default BOMSemanticMap;
  