import { Link } from "react-router-dom";
const NetworkDiagram = () => {
  return ( 
    <div> 
      <iframe src="https://bookofmormonexplorer.org/Widgets/SpeakersNetwork/index.html" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
      <br/><br/><br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
    </div>
   );
}
 
export default NetworkDiagram;