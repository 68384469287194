import { Link } from "react-router-dom";
const SimilarVerse = () => {
    return ( 
      <div> 
        <iframe src="https://bookofmormonexplorer.org/Widgets/SimilarVerse/index.html" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
        <br/>
        {/* This component is present in all pages except Feedback */}
        <Link to="/Feedback" className="footer"/>
      </div>
     );
  }
   
  export default SimilarVerse;
  